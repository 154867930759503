.ReactTable .rt-thead {
  overflow-y: scroll;
}

.ThemeHeader {

  background-color: #293042;
  color: white;
}

.IconsColor {
  color: #293042;
}

.popup-button {
  transition: transform 0.3s;
}

.popup-button:hover {
  transform: scale(1.1);
}

.popup-passwordmodal {
  transition: transform 0.6s;
}

.popup-passwordmodal:hover {
  transform: scale(1.4);
}

.popup-label {
  transition: transform 0.3s;
}

.popup-label:hover {
  transform: scale(1.3);
}

.button:hover {
  background-color: #293042;
  color: white;
}

.item-name {
  display: inline-block;
}

.total-quantity {
  display: none;
}

.item-name:hover+.total-quantity {
  display: inline-block;
}

.popup {
  position: absolute;
  top: -20px;
  left: 100%;
  background-color: #f1f1f1;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
  z-index: 1;
}

.flex {
  display: flex;
}

.isp-item-name{
  font-size: 18px;
  /* line-height: 30px; */
  border-bottom: 2px dotted black;
  padding-bottom: 5px;
  width: 200px;
  height: 30px;
  display: flex;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
  
}

.width-100 {
  width: 100%;
}

.isp-logo{
  width: 40%;
  text-align: center;
}

.isp-item-label{
  font-size: 9px;
  width: 8ch;
}

.isp-item-value{
  font-size: 9px;
  width: 70%;
}
.isp-barcode{
  position: absolute;
  bottom: -14px;
  right: 80px;
}

.sticker{
  width: 151px;
  height: 113px;
   box-sizing: border-box;
   background-color: #fff;
   /* transform: rotate(90deg); */
}