.hover-button {
  position: relative;
  padding: 10px 10px;
  border: none;
  background-color: #333;
  color: #fff;
  font-size: 16px;
  cursor: pointer;
  transition: color 0.3s ease-in-out;
  border-radius: 5px;
}

.hover-button:hover {
  color: transparent;
}

.hover-button::after {
  content: "Haseeb";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  color: #fff;
  opacity: 0;
  transition: opacity 0.8s ease-in-out, transform 0.8s ease-in-out;
  font-size: 18px;
}

.hover-button:hover::after {
  opacity: 1;
  transform: scale(1.2);
}


/* .hover-button {
  position: relative;
  padding: 10px 10px;
  border: none;
  background-color: #333;
  color: #fff;
  font-size: 16px;
  cursor: pointer;
  transition: color 0.3s ease-in-out;
  border-radius: 5px;
}

.hover-button:hover {
  color: transparent;
}

.hover-button::after {
  content: "Haseeb";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  color: #fff;
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
  font-size: 18px;
}

.hover-button:hover::after {
  opacity: 1;
  animation: jump 0.5s infinite;
}

@keyframes jump {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0);
  }
} */
