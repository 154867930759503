.popup-link {
    transition: transform 0.3s, color 0.3s;
    /* Add transition for smooth effect */
}

.popup-link:hover {
    transform: scale(1.1);
    /* Increase the scale of the link on hover */
    color: #293042;
    /* Change the color to red on hover */
}

/* .card .actions button {
    background-color: var(--btn-yellow);
    border: none;
    outline: none;
    width: 100%;
    padding: 1rem;
    border-radius: 1.5rem;
    font-size: 1rem;
    font-weight: bold;
    transition: background-color 200ms ease-in-out,
        transform 200ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

.card .actions button:hover {
    background-color: var(--btn-hover-yellow);
    transform: scale(1.1);
    cursor: pointer;
}

.card .actions button:active {
    background-color: var(--btn-zctive-yellow);
    transform: scale(1);
}


@import url("https://allfont.net/allfont.css?fonts=montserrat-light");

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family:montserrat-light, sans-serif;
}

:root {
    --light-blue: #1c9eff;
    --dark-blue: #007ad6;
    --btn-yellow: #ffd01a;
    --btn-hover-yellow: #afb10a;
    --btn-zctive-yellow: #afb10a;
    --light-grey: #f2f2f2;
    --grey: #a0a0a0;
    --pink: #ff9eaa;
}

body {
    background: linear-gradient(to bottom right, #fff, var(--pink));
    min-height: 100vh;
    display: grid;
    place-items: center;
}

.card {
    background-color: #fff;
    max-width: 360px;
    overflow: hidden;
    border-radius: 2rem;
    box-shadow: 0 15px 25px rgba(0, 0, 0, 0.5);
}

.card .banner {
    background: url("https://images.pexels.com/photos/2726046/pexels-photo-2726046.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: flex-end;
}

.card .banner .imgbox {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    overflow: hidden;
    border: 5px solid #fff;
    transform: translateY(50%);
    z-index: 1;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
    transition: transform 0.2s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

.card .banner .imgbox img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.card .banner .imgbox:hover {
    transform: translateY(50%) scale(1.2);
}

.card .card-body {
    position: relative;
    padding: 100px 30px 40px;
}

.card .card-body .menu {
    width: 40px;
    height: 40px;
    position: absolute;
    top: 10px;
    right: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 24px;
    border-radius: 50%;
    transition: backkground-color 100ms ease-in-out;
}

.card .card-body .menu:hover {
    cursor: pointer;
    background-color: var(--light-grey);
}

.card h2.name {
    text-align: center;
    padding-bottom: 10px;
    
}

.card .title {
    color: var(--grey);
    font-size: 14px;
    text-align: center;
    padding-bottom: 20px;
}

.card .actions {
    display: flex;
    flex-direction: column;
    padding-bottom: 20px;
}

.card .actions .follow-info {
    padding-bottom: 20ps;
    display: flex;
}

.card .actions .follow-info h2 {
    text-align: center;
    width: 50%;
    padding: 10px;
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    transition: background-color 200ms ease in out;
    font-weight: bold;
}

.card .actions .follow-info h2 span {
    color: var(--light-blue);
    font-weight: bold;
    font-size: 30px;
}

.card .actions .follow-info h2 span small {
    color: var(--grey);
    font-size: 14px;
    font-weight:normal;
    color: var(--grey);
}

.card .actions .follow-info h2:hover {
    background-color: var(--light-grey);
    cursor: pointer;
}

.card .actions .follow-info h2:hover span {
    color: var(--dark-blue);
}

.card .actions button {
    background-color: var(--btn-yellow);
    border: none;
    outline: none;
    width: 100%;
    padding: 1rem;
    border-radius: 1.5rem;
    font-size: 1rem;
    font-weight: bold;
    transition: background-color 200ms ease-in-out,
        transform 200ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

.card .actions button:hover {
    background-color: var(--btn-hover-yellow);
    transform: scale(1.1);
    cursor: pointer;
}

.card .actions button:active {
    background-color: var(--btn-zctive-yellow);
    transform: scale(1);
}
.card .desc{
  text-align: justify;
  color: var(--grey);
} */