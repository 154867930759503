.live-button {
    display: flex;
    align-items: center;
    background-color: transparent;
    border: none;
    color: white;
    font-size: 14px;
    font-weight: bold;
    /* padding: 5px; */
    cursor: pointer;
    /* padding-right: 5px; */
    margin-left: 115px;
  }
  
  .live-button .dot {
    width: 8px;
    height: 8px;
    background-color: #00ff00;
    border-radius: 50%;
    margin-right: 5px;
  }
  
  .live-button:hover {
    text-decoration: underline;
  }
  